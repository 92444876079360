@charset "UTF-8";

@import './styles/sass/abstracts/variables';
@import './styles/sass/abstracts/mixins';

//Montserrat
@font-face {
    font-family: Montserrat-Regular;
    src: local("Montserrat-Regular"), url(./styles/css/fonts/Montserrat/Montserrat-Regular.ttf); 
}
@font-face {
    font-family: Montserrat-Medium;
    src: local("Montserrat-Medium"), url(./styles/css/fonts/Montserrat/Montserrat-Medium.ttf); 
}
@font-face {
    font-family: Montserrat-SemiBold;
    src: local("Montserrat-SemiBold"), url(./styles/css/fonts/Montserrat/Montserrat-SemiBold.ttf); 
}
@font-face {
    font-family: Montserrat-Bold;
    src: local("Montserrat-Bold"), url(./styles/css/fonts/Montserrat/Montserrat-Bold.ttf); 
}

/*========================
		general
======*******************/
*{
    position:relative;
    z-index:5;
    font-size:1rem;
    font-family: "Montserrat-Medium", sans-serif;
    line-height:1.8rem;
    color:$noir;
}
  
body{
    overflow-x:hidden;
    &::-webkit-scrollbar{
      width:5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color:$cendre1;
       border-radius:10px;
    }
}

a{
    text-decoration:none;
    transition:all .3s ease-in;
}

button{
    transition:all .3s ease-in;
}

a:hover, a:focus, a:active {
    text-decoration: none; 
    color:inherit;
}
  
ul, ol {
    padding: 0;
    margin: 0 ; 
    margin-bottom:0 !important;
}
  
li{
    position: relative;
    list-style: none; 
}
  
img {
    width: 100%;
    height: auto; 
}
  
p{
    margin: 0 0 20px;
    overflow: hidden;
    display: block; 
}

small {
    display: block;
    font-size: 12px;
}
  
input, select, textarea{
    display:block;
    width:100%;
    height:42px;
    border-radius:10px;
    background-color:$blanc;
    border:1px solid $cendre2;
    padding-right:10px;
    padding-left:10px;
    font-size:14px;
    &:focus{
        outline:none;
    }
}

input, textarea{
    &::placeholder{
        color:$cendre2;
        font-size:12px;
    }
}


h2, h2.h2{
    font-size:28px;
    font-family:"Montserrat-bold", sans-serif;
    font-weight: 700;
    @media (min-width: 992px){
        font-size:30px;
    }
}
h3, h3.h3{
    font-size:18px;
    font-family:"Montserrat-Semibold", sans-serif;
    font-weight: 600;}

main.main{
    display:flex;
    flex-direction:column;
    min-height:calc(100vh - (189px + 80px));
    z-index:-1;
    overflow: hidden;
}


//form
.form{
    display:block;
    width:100%;
    p{
        background-color: $jaune;
        color:$blanc;
        border-radius:8px;
        font-family:"Montserrat-SemiBold", sans-serif;
    }
    &__item{
        label{
            font-size:14px;
            color:$cendre1;
            margin-bottom:3px;
        }
        input, select{
            color:$cendre1;
        }
        select{
            border-color:$jaune;
        }
        span{
            font-size: 10px;
            color: $orange;
            margin-top: -5px;
            display: block;
        }
    }
    & &__item + &__item{
        margin-top:20px;
    }
    &__grid{
        display:grid;
        grid-template-columns:repeat(2, 1fr);
        grid-gap:20px;
        margin-bottom:20px;
        .form__item{
            margin-top:0 !important;
        }
        @media (max-width:600px){
            grid-template-columns:repeat(1, 1fr);
        }
    }
}

//login-register

.log-reg{
    background-color:#f8f4f4;
    width:100%;
    min-height:100vh;
    &__content{
        padding-top:40px;
        padding-bottom:65px;
        max-width:650px;
        margin-right:auto;
        margin-left:auto;
        @media (max-width:767px){
            padding-right:15px;
            padding-left:15px;
        }
        @media (max-width:600px){
            width:100%;
        }
    }
    &__head{
        @include flex();
        margin-bottom: 15px;
        text-align:center;
        a:nth-child(2){
            @include flex($justify:start);
            color:$orange;
            font-size:15px;
            span.mdi{
                margin-right:10px;
                color:$orange;
            }
        }
    }
    &__logo{
        width:146px;
    }
    &__body{
        overflow:hidden;
        border-radius:10px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        background-color:$blanc;
    }
    &__banner{
        height:190px;
        background-color:$cendre2;
        overflow:hidden;
        @include flex($justify:center);
    }
    &__img{
        max-width: 400px;
        margin:0 15px;
    }
    &__form{
        padding:20px 70px 30px;
        p{
            @include flex();
            margin-top:25px;
            
            a{
                &.pwd-forgot{
                    font-size:14px;
                    color:$noir;
                    &:hover{
                        color:$orange;
                    }
                }
                &.link{
                    color:$orange;
                    font-size:14px;
                    span.mdi{
                        color:$orange;
                        font-size:14px;
                    }
                }
            }
        }
        @media (max-width:600px){
            padding-right:15px;
            padding-left:15px;
        }
    }
    .form{
        
        &__item{
            
            input{
                border-color:$jaune;
                /*&::placeholder{
                    color:$cendre2;
                    font-size:12px;
                }*/
            }
        }
        button{
            color:$noir;
            padding-right:60px;
            padding-left:60px;
        }
    }
    
}

//table

.ours{
    max-width: 800px;
    margin: 0 auto 35px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 4px 1px rgba(0,0,0,.11);
    overflow-x: auto;
}

table{
    width:100%;
    thead{
        width:100%;
        background-color: #d3e0dc;
        th{
            padding: 10px 15px;
            white-space: nowrap;
            font-family: "Montserrat-SemiBold",sans-serif;
            text-transform: uppercase;
        }
    }
    tbody{
        tr{
            //padding: 20px 0;
            td{
                padding:10px 0;
                &:first-child {
                    padding-left: 15px;
                    padding-right: 15px;
                    white-space: nowrap;
                }
                ul li {
                    padding: 5px 10px;
                    font-size: 14px;
                    small {
                        margin-top: -5px;
                        color: #828282;
                        line-height: 19px;
                        font-family: "Montserrat-Regular",sans-serif;
                    }
                }

            }
            
           
        }
    }
}