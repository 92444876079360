@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.input-search{
    border-radius:35px;
    padding-left:45px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
    &::placeholder{
        color:$cendre2;
    }
}