@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.mapouter{
    position:relative;
    text-align:right;
    width:100%;
    height:400px;
}
.gmap_canvas {
    overflow:hidden;
    background:none!important;
    width:100%;
    height:400px;
}
.gmap_iframe {
    height:400px!important;
}

.contact{
    &__content{
        padding-top:25px;
        padding-bottom:25px;
        @include flex($wrap:nowrap,$justify:start, $align:start);
        @media (max-width:991px){
            flex-wrap:wrap;
        }

    }
    &__left{
        width:450px;
        @media (max-width:991px){
            width:100%;
            order:2;
            margin-top:30px;
        }
    }
    &__right{
        width:calc(100% - 450px);
        padding-left:50px;
        input, textarea{
            border-color:$jaune;
            &::placeholder{
                color:$cendre;
            }
        }
        textarea{
            min-height:120px;
        }
        button{
            padding-right:45px;
            padding-left:45px;
            color:$noir;
        }
        @media (max-width:991px){
            width:100%;
            padding-left:0;
            order:1;
        }
    }
}