@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

//banner
.banner{
    min-height:calc(100vh - 80px);
    background-color:$cendre;
    &__way{
        position: absolute;
        bottom:0;
        left:0;
        width:550px;
        @media (max-width:991px){
            display:none;
        }
    }
    &__content{
        padding-top:35px;
        padding-bottom:35px;
        @include flex();
        @media (max-width:991px){
            justify-content:center;
        }
    }
    &__img{
        width:450px;
        img{
            width:100%;
        }
    }
    &__form{
        background-color:$blanc;
        border-radius:10px;
        overflow: hidden;
        padding:20px;
        width:350px;
        p{
            font-size:18px;
            font-family:'Montserrat-SemiBold', sans-serif;
        }
        @media (max-width:991px){
            width:600px;
            margin-top:40px;
        }
    }
}

//service
.service{
    box-shadow:0 4px 13px rga(0, 0, 0, 0.1);
    z-index:1;
    .line{
        position: absolute;
        width:180px;
        z-index:-1;
        &.line1{
            bottom:0;
            left:0;
        }
        &.line2{
            top:0;
            right:0;
        }
    }
    &__content{
        padding-top:50px;
        padding-bottom:60px;

    }
    &__card{
        @include flex($justify:space-around);
        
    }
    &__item{
        margin-top:45px;
        width:230px;
        text-align: center;
        h3{
            color:$jaune1;
        }
        p{
            font-size:14px;
            line-height:1.3rem;
        }
    }
    &__logo{
        height:100px;
        width:100px;
        border-radius:50%;
        border:1px dashed $jaune;
        margin-bottom:15px;
        margin-bottom: 30px;
        width: 100px;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        border-radius: 50%;
        background-color: #d3e0dc;
        &:before, &:after{
            content: "";
            position: absolute;
            z-index: 1;
            border: 1px solid #d3e0dc;
            border-radius: 50%;
            transition: all .8s ease;
        }
        &:after{
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &:before{
            top: -10px;
            left: -10px;
            width: 120%;
            height: 120%;
        }
        span{
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            font-size: 2.5rem;
            color: #fff;
            line-height: 1.5rem;
        }
        &:hover{
            background-color:$jaune;
            &:before{
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all .35s ease;
                border: 1px solid $jaune;
            }
            &:after{
                top: -10px;
                left: -10px;
                width: 120%;
                height: 120%;
                transition: all .8s ease;
                border: 1px solid $jaune;
            }
        }
    }
}

//phone
.phone{
    background-color:$cendre;
    z-index:-1;
    .round{
        position:absolute;
        img{
            width:initial;
        }
        &.round1{
            bottom:0;
            left:0;
        }
        &.round2{
            top:0;
            right:0;
        }
    }
    &__content{
        padding-top:100px;
        padding-bottom:100px;
        @include flex($justify:center);
    }
    &__img{
        width:239px;
        @media (max-width:767px){
            margin-left: 25px;
        }
    }
    &__text{
        text-align:left;
        width:277px;
        margin-left:119px;
        p{
            font-size:30px;
            font-family:"Montserrat-bold",sans-serif;
            line-height:45px;
        }
        @media (max-width:991px){
            margin-left:60px;
            p{
                font-size:25px;
            }
        }
        @media (max-width:767px){
            margin-left:0;
            margin-top:35px;
            width:300px;
            text-align:center
        }
    }
    &__app{
        display:inline-block;
        width:175px;
    }
}

//partner
.partner{
    overflow:hidden;
    &__bg{
        width:235px;
        position:absolute;
        top:80px;
        left:50%;
        transform:translateX(-50%);
    }
    &__content{
        padding-top:80px;
        padding-bottom:80px;
        overflow:hidden;
    }
    &__list{
        margin-top:35px;
        .slick-arrow{
            display:none !important;
        }
        .slick-track{
            display:flex;
        }
        .slick-slide{
            margin:0 5px;
            display:flex;
            justify-content: center;
        }
    }
    &__item{
        width:120px !important;
        height:120px !important;
        background-color: $blanc;
        margin:0 5px;
        border-radius:50%;
        display:flex !important;
        @include flex($justify:center);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        img{
           width:80px;
        }
    }
}