@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.user-admin header, 
.user-admin footer{
    display:none;
}

.admin{
    //@include flex($wrap:nowrap,$justify:start, $align:start);
    &__menuList{
        padding-top: 75px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        @media (max-width:1024px){
            grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width:767px){
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width:525px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width:395px){
            grid-template-columns: 100%;
        }
        a{
            margin:20px 15px 0 15px;
            height:120px;
            @include flex($justify:center);
            flex-direction:column;
            padding:15px;
            text-align:center;
            background-color:#f8f4f4;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
            span.mdi{
                font-size:3rem;
                margin-bottom:15px;
                color:$orange;
            }
            span:last-child{
                font-size:14px;
            }
            &:hover{
                top:-5px;
            }
        }
    }
}