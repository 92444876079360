@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.nos-gares{
    &__map{
        //height:100%;
        position:absolute;
        top:0;
        left:0;
        width:100%;
        display: grid;
        grid-template-columns: 100%;
        opacity: .09;
        .mapouter{
            height:100%;
            object-fit: cover;
            .gm-style .place-card-large{
                display:none !important;
            }
        }
    }
}

.filter-g-i{
    max-width:800px;
    margin:30px auto 15px auto;
    span.mdi{
        position:absolute;
        top:50%;
        left:15px;
        transform:translateY(-50%);
        color:$cendre2;
        z-index: 6;
        font-size:1.8rem;
    }
}