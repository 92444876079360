@import '../../../../../styles/sass/abstracts/variables';
@import '../../../../../styles/sass/abstracts/mixins';

.mes-tickets header, .mes-tickets footer{
    display:none;
}

.admin{

    &__content{
        padding-top: 75px;
        padding-bottom:45px;
        .react-tabs{
            &>ul{
                margin:15px 15px 30px !important;
                border-bottom: 1px solid #aaaaaa78;
                white-space: nowrap;
                overflow-x: auto;
                overflow-y: hidden;
                box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
                border-radius:35px;
                &::-webkit-scrollbar-track{
                    box-shadow: insed 0 0 6px regba(0, 0, 0, 0.3);
                    background-color:#d3e0dc85;
                    height:5px;
                }
                &::-webkit-scrollbar{
                    width:3px;
                    height:4px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color:$cendre2;
                    border-radius:10px;
                }
                li{
                    font-size:14px;
                    &.react-tabs__tab--selected{
                        border:transparent;
                        color:$orange;
                    }
                    &:focus{
                        box-shadow:none;
                        border:none;
                    }
                }
            }
            .react-tabs__tab-panel{
                margin:15px;
                h3{
                    font-size:24px;
                }
            }
        }
    }
    &__newTicket{
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
        padding:25px;
        border-radius:10px;
        input, select{
            border-color:$jaune;
        }
        button{
            @include buton()
        }
    }
    &__allTicket{
        //@include flex($justify:space-around)
        display:grid;
        grid-template-columns:repeat(3, 1fr);
        grid-gap:10px;
        grid-row-gap: 25px;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
        padding-bottom:25px;
        min-height:400px;
        border-radius:10px;
        .empty-fetch{
            padding: 50px 10px;
            fontSize: 14px;
            position: sticky;
            left: 50%;
            transform: translateX(-50%);
            text-align:center;
        }
        @media (max-width:991px){
            grid-template-columns:repeat(2, 1fr);
        }
        @media (max-width:767px){
            grid-template-columns:repeat(1, 1fr);
            .empty-fetch{
                left:initial;
                transform:initial;
                position:initial;
            }
        }
    }
}
.ticket-pagination{
    position:absolute;
    bottom:-65px;
    left:50%;
    transform:translateX(-50%);
    nav{
        margin:0;
    }
}

//form ticket
.bus{
    &__available{
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        white-space: nowrap;
        padding: 15px 5px 15px 5px;
        cursor:pointer;
        &::-webkit-scrollbar-track{
            box-shadow: insed 0 0 6px regba(0, 0, 0, 0.3);
            background-color:#d3e0dc85;
            height:5px;
        }
        &::-webkit-scrollbar{
            width:3px;
            height:5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color:$cendre2;
            border-radius:10px;
        }
    }
    &__item{
        margin-top:15px;
        width:285px;
        @include flex($wrap:nowrap, $justify:flex-start, $align:flex-start);
        display:flex !important;
        background-color:$blanc !important;
        padding:16px 10px 7px 10px !important;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
        
    }
    &__item + &__item{
        margin-left:20px;
    }
    &__info{
        width:calc(100% - 100px);
        @include flex();
        flex-direction:column;
        padding-right:10px;
    }
    &__time, &__placetotal, &__placeRest, &__prix{
        @include flex($wrap:nowrap);
        display:flex !important;
        span, time{
            font-size:12px !important;
            color:$noir !important;
            
        }
        span:nth-child(2), time{
            font-weight:600 !important;
        }
    }
    &__img{
        width:110px;
        height:20px;
        text-align:center;
        span:first-child{
            font-size: 14px;
            font-weight:400;
            color:$noir !important;
            margin-bottom:2px;
        }
    }
    &__v{
        background-color:$cendre2;
        height:50px;
        line-height: 50px;
        overflow:hidden;
        border-radius:5px;
        img{
            width:80%;
        }
    }
}

//seat-available
.seat-available{
    &__list{
        //@include flex($justify:flex-start);
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        max-height:195px;
        overflow:auto;
        &::-webkit-scrollbar{
            width:3px;
            height:5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color:$cendre2;
            border-radius:10px;
        }
        @media (max-width:991px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width:767px){
            grid-template-columns: repeat(1, 1fr);
        }

    }
    &__item{
        width:280px;
        margin: 10px 5px 0 5px;
        @media (max-width:767px){
            width:90%;
        }
    }
    &__box{
        @include flex($wrap:nowrap);
        height: 55px;
        padding: 0 5px;
        border-radius:5px;
        border:1px solid $cendre2;
        background-color:#f8f4f4;
        input{
            display: block;
            width: 25px;
            height: 30px;
            margin-left:5px;
            cursor:pointer;
        }
    }
    &__position{
        height: 23px;
        width: 36px;
        text-align: center;
        background: $cendre2;
        margin-right: 5px;
        border-radius: 0;
        font-size: 13px !important;
        line-height: 22px;
        color:$noir !important;
        box-shadow: -1px 2px 0px 1px silver;
    }
    &__desc{
        font-size: 12px !important;
        line-height: 15px;
        color:$noir !important;
    }
    &__statu{
        background-color:green;
        color:$blanc !important;
        padding:0 5px;
        border-radius:5px;
        font-size: 12px !important;
        //font-family: "Montserrat-SemiBold", sans-serif;
        &-disabled{
            background-color:$cendre1 ;
            color:$noir !important;
        }
    }
}

//ticket
.ticket{
    
    &__content{
        width:280px;
        overflow:hidden;
        padding:15px;
        border-radius:10px;
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
        //box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
        margin: 0 auto;
        @media (max-width: 325px){
            width:100%;
        }
    }
    &__enterpriseLogo{
        img{
            width:80px
        }
        span{
            display:block;
            font-family: "Montserrat-Bold", sans-serif;
            margin-top:-5px;
            font-size:13px;
        }
    }
    &__enterpriseInfo{
        span{
            font-family: "Montserrat-SemiBold", sans-serif;
            display:block;
            font-size:11px;
            line-height:15px;
            color:$cendre1;
        }
    }
    &__head{
        @include flex($wrap:nowrap);
        margin-bottom:0;
    }
    &__statu{
        
        span{
            white-space:nowrap;
            font-size:12px;
            padding:2px 8px;
            border-radius:35px;
            color:$blanc;
        }
        &.valide span{
            background-color:$jaune;
        }
        &.invalide span{
            background-color:transparent;
            color:green;
            border:1px solid green;
        }
    }
    &__bus{
        border-radius:35px;
        white-space:nowrap;
        font-family:"Montserrat-Bold",sans-serif;
        font-size:13px;
    }
    &__body{
        h5{
            font-size:16px;
            display:flex;
            flex-wrap: wrap;
            align-items:center;
            span.mdi{
                font-size:1.2rem;
                margin-left:5px;
                color:$jaune;
            }
            span.fullname{
                width:100%;
                font-family:"Montserrat-Bold",sans-serif;
                font-size: 14px;
            }
        }
    }
    &__info{
        margin-top: -10px;
        ul{
            
            li{
                font-size:12px;
                color:$cendre1;
                span, time{
                    font-size:11px;
                    margin-top:-7px;
                    line-height:14px;
                    color:$noir;
                }
                & + li{
                    margin-top:0px;
                }
            }
        }
    }
    &__button{
        display:flex;
        align-items:center;
        justify-content:center;
        height:40px;
        width:40px;
        border-radius:50%;
        float:right;
        border:transparent;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
        background-color:$blanc;
        position: absolute;
        right: 0;
        bottom: -3px;
        span.mdi{
            font-size:1.4rem;
            color:$cendre;
        }
        &:hover{
            background-color:$jaune;
            span.mdi{
                color:$blanc;
            }
        }
    }
    &__qrCode{
        position: absolute;
        top: -27px;
        right: 0;
    }
}