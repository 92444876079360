@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.footer{
    background-color:$cendre;
    &-top{

        &__content{
            @include flex($wrap:nowrap, $align:start);
            padding-top:35px;
            padding-bottom:35px;
            @media (max-width:767px){
                flex-wrap:wrap;
            }
        }
        &__menu{
            width:100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            //grid-gap:10px;
            @media (max-width:600px){
                grid-template-columns: 100%;
            }
            li {
                a{
                    margin-left:0 !important;
                    padding-left:16px;
                    white-space:nowrap;
                    &:before{
                        display:none;
                    }
                    span.mdi{
                        position:absolute;
                        top:50%;
                        transform:translateY(-50%);
                        left:0;
                        transition:all .3s ease-in;
                    }
                    &.active{
                        color:$blanc !important;
                        padding-left:20px;
                        span{
                            color:$blanc;
                        }
                    }
                    &:hover{
                        color:$blanc !important;
                        padding-left:20px;
                        span{
                            color:$blanc;
                        }
                    }
                }
            }
        }
        &__follow{
            width:max-content;
            @media (max-width:767px){
                width:100%;
                margin-top:25px;
            }
            p{
                white-space: nowrap;
                font-size:16px;
                margin-bottom:15px;
            }
            
        }
    }
    &-bottom{
        background-color:$cendre1;
        &__content{
            font-size:12px;
            color:$cendre2;
            padding-top:5px;
            padding-bottom:5px;
            a{
                font-size: 12px;
                color:$blanc;
                font-weight: 600;
            }
        }
    }
}

.rs{
    @include flex($wrap:nowrap, $justify:start);
    a{
        @include flex($justify:center);
        height:38px;
        width:38px;
        border-radius:50%;
        background-color:$blanc;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        transition:all .3s ease-in;
        overflow: hidden;
        &:before{
            content:"";
            height: 5px;
            width:5px;
            background-color:$orange;
            opacity: 0;
            position:absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
            transition:all .3s ease-in;
            border-radius: 50%;
        }
        span.mdi{
            color:$orange;
            font-size:1.4em;
            transition:all .3s ease-in;
        }
        &:hover{
            box-shadow:none;
            background-color:transparent;
            &:before{
                height:100%;
                width:100%;
                opacity:1;
            }
            span.mdi{
                color:$blanc;
            }
        }
    }
    & a + a{
        margin-left:15px;
    }
}