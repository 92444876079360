@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.bg-text{
    @include flex($justify:center);
    padding-top:40px;
    
}
.box{
    display:flex;
    .inner{
        width:300px;
        height:70px;
        line-height:70px;
        font-size:4em;
        font-weight:bold;
        white-space: nowrap;
        overflow: hidden;
        display:flex;
        align-items: center;
        flex-wrap:nowrap;
        span{
            position:absolute;
            animation:marquee 5s linear infinite;
            font-size:2rem;
        }
        &:first-child{
            background-color:$cendre;
            color:darkred;
            transform-origin: right;
            transform:perspective(100px) rotateY(-15deg);
            span{
                animation-delay:2.5s;
                left:-100px;
            }

        }
        &:last-child{
            background-color:$cendre2;
            color:antiquewhite;
            transform-origin: left;
            transform: perspective(100px) rotateY(15deg);
            span{
                color:#fff;
            }
        }
    }
}

@keyframes marquee{
    from{
        left: 100%;
    }
    to{
        left:-100%;
    }
}

.b-text{

    &__content{
        padding-top:5px;
        padding-bottom:45px;
    }
    &__card{
        margin:40px auto 0 auto;
        max-width: 900px;
        h4.h4{
            font-size:1rem;
            font-family:"Montserrat-SemiBold", sans-serif;
            padding-left:40px;
            margin-bottom:20px;
            span{
                display:inline-block;
                height:30px;
                width:30px;
                background-color:$cendre2;
                color:$noir;
                @include flex($justify:center);
                border-radius:50%;
                font-size:14px;
                position:absolute;
                left:0;
                top:50%;
                transform:translateY(-50%);
            }
        }
        p{
            font-size:14px;
            padding-left:20px;
            text-align:justify;
            line-height: 23px;
            opacity:.7;
            margin-bottom:30px;
        }
    }
}