@import '../../../../../../styles/sass/abstracts/variables';
@import '../../../../../../styles/sass/abstracts/mixins';

.myprofil{
	box-shadow:0 3px 8px rgba(0, 0, 0, 0.2);
	&__head{
		height:100px;
		background-color:$cendre2;
	}
	&__img{
		position:absolute;
		top:50px;
		left:25px;
		height:120px;
		width:120px;
		border-radius:50%;
		overflow:hidden;
		border: 3px solid $blanc;
		background-color:$blanc;
		img{
			width:100%;
			height:100%;
			object-fit:cover;
		}
		@media (max-width:991px){
			left:50%;
			transform:translateX(-50%);
		}
	}
	&__info{
		margin-top:25px;
		padding-bottom:25px;
		padding-left:200px;
		@media (max-width:991px){
			width: max-content;
			margin:95px auto 0 auto;
			padding:0 25px 25px 25px;
		}
		ul {
			display:grid;
			grid-template-columns:repeat(3, 1fr);
			@media (max-width:991px){
				grid-template-columns:100%;
			}
			li{
				font-size:14px;
				span{
					margin-right:5px;
					color:$orange
				}
			}
		}
	}
}