@import '../../../../../../styles/sass/abstracts/variables';
@import '../../../../../../styles/sass/abstracts/mixins';

.send-colis{
	box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
	padding:25px;
	border-radius:10px;
	.form input{
		border-color:$jaune;
	}
	.form textarea{
		border-color:$jaune;
		min-height:80px;
	}
	.MuiInput-root:before{
		display:none !important;
	}
	.css-xtlx1y-MuiGrid-root>.MuiGrid-item:nth-child(1){
		padding-top:30px;
	}
	.form__item.send-colie{
		display:flex;
		align-items:center;
		&>label{
			margin-right:25px;
		}
		&>span{
			margin-top:0;
		}
	}
	.form__item > span.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
		margin-top:0;
	}
	.form__item input.css-1m9pwf3{
		z-index:100;
	}
	.form__item span{
		margin-top:0 !important;
	}
	.form__item span.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
		background-color:#f28031;
	}
}