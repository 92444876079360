@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.about-ours{
    &:before{
        content:"";
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        width:calc(100% - 20px);
        height:calc(100% - 20px);
        border:1px solid lighten($cendre2, 10);
    }
    .car{
        position:absolute;
        right:15px;
        bottom:25px;
        width:300px;
        z-index:-1;
        opacity: .1;
    }
    &__content{
        padding-top:45px;
        padding-bottom:45px;
    }
    &__card{
        @include flex($wrap:nowrap, $justify:flex-start);
        margin:40px 0;
        @media (max-width:991px){
            flex-wrap:wrap;
        }
    }
    &__img{
        width:500px;
        height:300px;
        overflow:hidden;
        border-radius:10px;
        //box-shadow: 0px 1px 10px #00000047;
        background-color:#333;
        img{
            height:100%;
            object-fit: fill;
            transition: all .3s ease-in;
            &:hover{
                transform:scale(1.15, 1.15);
            }
        }
        @media (max-width:991px){
            width:100%;
        }
    }
    &__text{
        width:calc(100% - 500px);
        padding-left:25px;
        p{
            font-size:14px;
            opacity:.7;
            text-align:center;
        }
        @media (max-width:991px){
            padding-left:0;
            width:100%;
            margin-top:25px;
            p{
                text-align:left;
            }
        }
    }
}


.about-equipe{
    background-color:#f8f4f49c;
    box-shadow: 0 4px 6px rgba(0 , 0, 0, 0.10);
    &__content{
        padding-top:50px;
        padding-bottom:30px;
    }
    &__list{
        margin:30px 0;
        @include flex($justify:space-around);
    }
    &__item{
        margin:30px 5px 0;
        width:250px;
        h3.h3{
            font-family:"Arizonia-Regular", sans-serif;
            font-size:26px;
            font-weight:400;
            color:$orange;
        }
        p{
            margin-top:15px;
            font-size:14px;
            opacity:.7;
            line-height: 20px;
        }
    }
    &__img{
        clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
        width:200px;
        height:200px;
        overflow:hidden;
        margin: 0 auto;
        img{
            height:100%;
            object-fit: cover;
            transition: all .3s ease-in;
            &:hover{
                transform:scale(1.15, 1.15);
            }
        }
    }
}

.about-politique{

    .about-ours__img{
        order:2;
        @media (max-width:991px){
            order:inherit;
        }
    }
    .about-ours__text{
        padding-right:25px;
        padding-left:0;
        @media (max-width:991px){
            padding-right:0;
        }
    }
}