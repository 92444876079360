//color
$blanc:#fff;
$noir:#000;
$jaune: #fcc628;
$jaune1:#ffb700;
$jaune2:#f6af49;
$cendre:#9ab3aa;
$cendre1:#828282;
$cendre2:#d3e0dc;
$orange:#f27f17;
$maron:#cf8051;
$vert:#326e3c;
$vert1:#449450;