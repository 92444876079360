@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.header{
    background-color:$blanc;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    z-index:6;
    &.fixed{
        position:fixed;
        top:0;
        left:0;
        width:100%;
    }
    &__content{
        padding-top:13px;
        padding-bottom:12px;
        @include flex();
    }
    &__logo{
        display:block;
        max-width:146px;
        img{

        }
    }
    &__center{
        @media (max-width:991px){
            padding-top:30px;
            position:fixed;
            top:120px;
            left:0;
            width:100%;
            height:calc(100vh - 80px);
            background-color: $cendre;
            opacity: 0;
            visibility: hidden;
            transition:all .3s ease-in;
            &.active{
                opacity: 1;
                visibility: visible;
                top:80px;
            }
            .menu{
                flex-direction: column;
                li{
                    a{
                        margin-left:0 !important;
                        font-size:20px;
                        &:before{
                            display:none;
                        }
                        &.active{
                            color:$blanc;
                        }
                        &:hover{
                            color:$blanc;
                        }
                    }
                    & + li{
                        margin-top:20px;
                    }
                }
            }
        }
    }
    &__right{
        a{
            @include buton();
            &:first-child{
                margin-right:15px;
            }
        }
        @media (max-width:991px){
            margin-right:55px;
        }
        @media (max-width:767px){
            display:none;
        }
        
    }
    &__btnMenu{
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        right:0;
        height:45px;
        width:45px;
        display:none;
        @media (max-width:991px){
            display:block;
        }
        span{
            position: absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            height:3px;
            width:33px;
            background-color:$noir;
            transition:all .3s ease-in;
            border-radius:3px;
            &:before, &:after{
                content:"";
                position:absolute;
                left:50%;
                transform:translateX(-50%);
                height:3px;
                width:33px;
                background-color:$noir;
                transition:all .3s ease-in;
                border-radius:3px;
            }
            &:before{
                top:-8px;
            }
            &:after{
                bottom:-8px;
            }
            &.active{
                background-color:transparent;
                &:before{
                    top:0;
                    transform:translateX(-50%) rotate(45deg);
                }
                &:after{
                    bottom:0;
                    transform:translateX(-50%) rotate(-45deg);
                }
            }
            
        }
    }
}
.menu{
    @include flex();
    &__item{
        display:inline-size;
        a{
            font-size:14px;
            color:$noir;
            display:block;
            &:before{
                content:"";
                position:absolute;
                bottom:0;
                left:0;
                width:0;
                height:1px;
                background-color: $orange;
                transition:all .3s ease-in;
            }
            &.active{
                color:$orange;
                font-family: 'Montserrat-SemiBold', sans-serif;
            }
            &:hover{
                color:$orange;
                &:before{
                    width:100%;
                }
            }
        }
        & + & a{
            margin-left:25px;
        }
        &.log-reg-action{
            display:none;
            @media (max-width:767px){
                display:block;
            }
        }
    }
}

#btn-home{
    position:fixed;
    bottom:20px;
    right:20px;
    height:40px;
    width:40px;
    border-radius:50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    text-align:center;
    background-color:$orange;
    z-index:1000;
    span{
        line-height:40px;
        font-size:1.5rem;
        color:$blanc;
    }
}