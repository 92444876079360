@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.sidebar{
    min-width:250px;
    width:250px;
    height:100vh;
    background-color:$noir;
    z-index: 100;
    transition:all .3s ease-in;
    &__logo{
        height:60px;
        margin: 5px 15px;
        background-color:$blanc;
        padding: 5px 15px;
        border-radius:35px;
        img{
            height:100%;
            width:100%;
            object-fit:contain;
        }
    }
    &__menu{
        margin-top:50px;
    }
    &__item{
        overflow:hidden;
        a{
            color:$blanc;
            padding:6px 10px 6px 15px;
            margin:0 15px;
            background-color:#e06c1b;
            color:$blanc;
            display:block;
            border-radius:35px;
            &.active{
                background-color:$blanc;
                color:$orange;
            }
            &:focus{
                color:$orange;
            }
            &:hover{
                background-color:$blanc;
                color:$orange;
            }
        }
        & + &{
            margin-top:5px;
        }
    }
}