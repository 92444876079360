@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.m-legales{
	&__title{
		text-align:center;
		margin:25px 0 30px;
		&-content{
			display:inline-block;
			text-align:center;
			max-width:300px;
			border:1px solid $cendre2;
			border-radius:6px;
			padding:15px 35px;
			//background-color:$cendre2;
			span{
				font-family:'Montserrat-Bold',sans-serif;
				font-size:1.5rem;
			}
		}
	}
}

.m-text{
	&__card{
		margin: 40px auto 0;
	    max-width: 600px;
	    p{
	        font-size: 14px;
		    padding-left: 45px;
		    text-align: justify;
		    line-height: 23px;
		    opacity: .7;
		    margin-bottom: 30px;
		    &:first-child{
		    	padding-left:0;
		    }
		    span{
			    height: 30px;
			    width: 30px;
			    background-color: #d3e0dc;
			    color: #000;
			    display: flex;
			    flex-wrap: wrap;
			    justify-content: center;
			    align-items: center;
			    border-radius: 50%;
			    font-size: 14px;
			    position: absolute;
			    left: 0;
			    top: 50%;
			    -webkit-transform: translateY(-50%);
			    transform: translateY(-50%);
		    }
	    }
	}
}