//flex
@mixin flex($wrap:wrap, $justify:space-between, $align:center) {
    display:flex;
    flex-wrap:$wrap;
    justify-content: $justify;
    align-items: $align;
}

//button
@mixin buton($pd: 6px 10px, $bg:$jaune, $clr:$blanc){
    border:transparent;
    background-color:$jaune;
    color:$blanc;
    display:inline-block;
    padding:$pd;
    border-radius:10px;
    font-size: 15px;
    &:hover{
        background-color:$jaune1;
        color:$blanc;
    }
}