@import '../../../../../../styles/sass/abstracts/variables';
@import '../../../../../../styles/sass/abstracts/mixins';

.update-profile{
	box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
	padding:25px;
	border-radius:10px;
	.form input{
		border-color:$jaune;
	}
}