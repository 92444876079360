@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';


.pagination{
	justify-content:center;
	margin: 30px 0 32px 0;
	ul{
		display:flex !important;
		li{
			display:inline-block;
			text-align:center;
			& + li {
				margin-left:10px;
			}
			a{
				font-size:14px;
				width:30px;
				height:30px;
				line-height:30px;
				padding:0;
				border-radius:5px;
				color:$cendre1;
				&:hover{
					color:$cendre1;
				}
				&.page-link:focus{
					color:$blanc;
					background-color:$cendre1;
					box-shadow: 0 0 0 0.15rem rgba(13,110,253,.25);
				}
			}

		}
	}
}