@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.ad-head{
    display:block;
    box-shadow:0 4px 10px rgba(0, 0, 0, 0.15);
    background-color:$blanc;
    z-index:6;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    &__content{
        @include flex($wrap:nowrap);
        height:70px;
    }
    &__btn{
        span.mdi{
            font-size:2rem;
            transition: all .3s ease-in;
        }
        &:hover span{
            color:$orange;
        }
    }
    &__action{
        @include flex($wrap:nowrap, $justify:flex-start);
        a{
            @include flex($wrap:nowrap, $justify:flex-start);
            span{
                &:nth-child(1){
                    display:block;
                    height:40px;
                    width:40px;
                    border-radius:50%;
                    overflow:hidden;
                    img{
                        width:100%;
                        height:100%;
                        object-fit:cover;
                    }
                }
            }
        }
        .dropdown{
            position:absolute;
            right:10px;
            top:70px;
            background-color:$blanc;
            z-index:1000;
            border-radius:10px;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
            padding:4px;
            visibility:hidden;
            opacity:0;
            transition: all .3s ease-in;
            &.active{
                top:60px;
                visibility:visible;
                opacity:1;
            }
            a{
                white-space:nowrap;
                border:1px solid $cendre2;
                padding:0px 5px;
                border-radius:5px;
                color:$noir;
                font-size:14px;
                font-family:"Montserrat-SemiBold",sans-serif;
                background-color:#f2803145;
                &:hover{
                    background-color:$blanc;
                    color:$orange;
                }
            }
            & a + a{
                display:block;
                margin-top:5px;
            }
        }
    }
}