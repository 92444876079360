@import '../../../../../styles/sass/abstracts/variables';
@import '../../../../../styles/sass/abstracts/mixins';

.portefeuille header, .portefeuille footer{
    display:none !important;
}
.admin{
    &__balance{
        max-width:450px;
        height:200px;
        margin:40px auto 15px auto;
        padding:15px 20px;
        border-radius:15px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
        p > span{
            font-family:"Montserrat-Bold", sans-serif;
            font-size:1.5rem;
        }
        &>span.devise{
           position:absolute;
           left:20px;
           bottom:15px; 
        }
    }
    &__newb{
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
        padding:25px;
        border-radius:10px;
    }
    &__demande{
        margin-top:25px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
        nav.pagination{
            margin: 20px 0;
        }
        &-list{
            overflow:auto;
            &::-webkit-scrollbar-track{
                box-shadow: insed 0 0 6px regba(0, 0, 0, 0.3);
                background-color:#d3e0dc85;
                height:5px;
            }
            &::-webkit-scrollbar{
                width:3px;
                height:5px;
            }
            &::-webkit-scrollbar-thumb {
                background-color:$cendre2;
                border-radius:10px;
            }
        }
        table {
            th{
                font-size:14px;
            }
            tr td{
                padding:10px 15px;
                white-space: nowrap;
                font-size:14px;
                span{
                    font-size:13px;
                    padding: 0px 10px;
                    width: 90px;
                    display: inline-block;
                    text-align: center;
                    border-radius:35px;
                    color:$blanc;
                    &.wait{
                        background-color:$jaune;
                    }
                    &.finish{
                        background-color:green;
                    }
                }
                &:nth-child(2){
                    font-family:'Montserrat-SemiBold',sans-serif;
                }
                &:last-child{
                    text-align:center;
                }
            }
        }
    }
    &__historique{
        margin-top:25px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
        nav.pagination{
            margin: 20px 0;
        }
        &-list{
            overflow:auto;
            &::-webkit-scrollbar-track{
                box-shadow: insed 0 0 6px regba(0, 0, 0, 0.3);
                background-color:#d3e0dc85;
                height:5px;
            }
            &::-webkit-scrollbar{
                width:3px;
                height:5px;
            }
            &::-webkit-scrollbar-thumb {
                background-color:$cendre2;
                border-radius:10px;
            }
        }
        table {
            th{
                font-size:14px;
            }
            tr td{
                padding:10px 15px;
                white-space: nowrap;
                font-size:14px;
                &:nth-child(3){
                    time{
                        font-size:14px;
                    }
                }
                &:nth-child(4){
                    span.credit{
                        color:green;
                        font-size:14px;
                        span{
                            color:green;
                        }
                    }
                    span.debit{
                        color:red;
                        font-size:14px;
                        span{
                            color:red;
                        }
                    }
                }
            }
        }
    }
}

.info_momo{
    background-color:#80008052;
    padding:10px;
    margin: 30px 0 40px 0;
    p{
        margin-bottom:0 !important;
    }
}