@import '../../../../styles/sass/abstracts/variables';
@import '../../../../styles/sass/abstracts/mixins';

.crumb{
    background-color:$cendre2;
    &__list{
        @include flex($justify:start);
    }
    &__item{

        a{
            font-size: 14px;
            color:$noir;
            &:hover{
                color:$orange;
            }
        }
        &:nth-child(2){
            padding-left:18px;
            a{
                color:$orange;
            }
        }
    }
}